<template>
  <!-- Start Content-->
  <div>
    <div class="responsive-table-plugin btn-toolbar align-items-center justify-content-between">
      <!--  -->
      <div style="display: flex;">
        <div class="form-inline md-form form-sm toolbar-search">
          <label>
            <div class="language" v-html="lan.BuildingName"></div>:
          </label>
          <input
            class="form-control mr-1 w-80"
            type="search"
            placeholder="Search"
            aria-label="Search"
            v-model="listStrage.query.BuildingName"
          />
        </div>

        <div class="form-inline md-form form-sm toolbar-search">
          <label>
            <div class="language" v-html="lan.Address"></div>:
          </label>
          <input style="width: 150px;"
            class="form-control mr-1 w-80"
            type="search"
            v-model="listStrage.query.Address"
          />
        </div>

        <!-- 2 -->
        <div class="form-inline md-form form-sm toolbar-search">
          <label>
            <div class="language" v-html="lan.areaFrom"></div>:
          </label>
          <input
            style="width:80px"
            class="form-control mr-1 w-40"
            type="number"
            placeholder
            aria-label
            v-model="listStrage.query.areafrom"
            @keyup.enter="search"
          />
        </div>
        <!-- 2 -->

        <!-- 3 -->
        <div class="form-inline md-form form-sm toolbar-search">
          <label>
            <div class="language" v-html="lan.areaTo"></div>:
          </label>
          <input
            style="width:80px"
            class="form-control mr-1 w-40"
            type="number"
            placeholder
            aria-label
            v-model="listStrage.query.areato"
            @keyup.enter="search"
          />
        </div>
        <!-- 3 -->

        <!-- 4 -->
        <div class="form-inline md-form form-sm toolbar-search">
          <label>
            <div class="language" v-html="lan.move_date"></div>:
          </label>
          <select class="form-control" v-model="listStrage.query.movedate">
            <option value>{{lan.all}}</option>
            <option
              v-for="(item,index) in moveingTypeList"
              :key="index"
              :value="item.value"
              :selected="{true : index == 1}"
            >{{item.text}}</option>
          </select>
        </div>
        <div class="form-inline md-form form-sm toolbar-search" style="margin-left: 30px;">
          <button type="button" class="btn btn-primary" @click="search">
            <i class="fas fa-search" aria-hidden="true"></i>
          </button>
        </div>
      </div>
      <!-- 4 -->

      <!-- <div
        style="white-space: nowrap;"
        class="form-inline md-form form-sm toolbar-search"
      >
        {{lan['BuildingName']}}: &nbsp;
        <input class="form-control mr-1 w-200" type="text" v-model="BuildingName" />&nbsp;&nbsp;
        {{lan['TotalArea_SqM']}}: &nbsp;
        <input class="form-control mr-1 w-200" type="text" v-model="TotalAreaSqM" />&nbsp;&nbsp;
        {{lan['expiryDate']}}: &nbsp;
        <input class="form-control mr-1 w-200" type="date" v-model="expiryDate" />&nbsp;&nbsp;
        
        <button type="button" class="btn btn-primary" @click="search">
          <i class="fas fa-search" aria-hidden="true"></i>
        </button>
      </div>-->
      <div class="form-inline toolbar-display" style="margin-top: 5px;">
        <div class="mr-2 toolbar-display-count">
          <span class="mr-1">{{$language('表示件数')}}</span>
          <div>
            <select class="form-control mt-0" v-model.lazy="listStrage.limit" @change="search">
              <option>10</option>
              <option>50</option>
              <option>100</option>
              <option>200</option>
              <option>500</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center p-5" v-if="isLoading">
      <div class="spinner-border m-12" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-else-if="!jsonErr && !checkKeys.length">
      <!-- 表示項目無し -->
      <code>{{$language('表示項目が選択されていません')}}</code>
    </div>
    <div class="responsive-table-plugin" v-else-if="!jsonErr">
      <div class="table-rep-plugin">
        <div class="table-responsive" style="transform: scaleY(-1);">
          <table class="table table-striped focus-on" style="transform: scaleY(-1);">
            <thead>
              <tr>
                <th
                  v-for="(tableHeader,key) in tableHeaders"
                  :key="key"
                  :class="['table-row-' + tableHeader,key == 'rank' ? 'wide-100' : '']"
                >{{ lan[tableHeader] || tableHeader }}</th>
                <th class="wide-100"></th>
              </tr>
            </thead>
            <tbody v-if="jsonObj.count && checkKeys.length">
              <tr
                v-for="(obj, idNum) in listData"
                :key="idNum"
           
                @click="toggleFocus(idNum)"
                @child="toggleFocus(idNum)"
              >
                <list-table-row
                  :row="obj"
                  :checkList="checkKeys"
                  info_id="BuildingName"
                  :jump_url="'/build_info?BuildingID='+ obj['BuildingID']"
                />
                <td>
                  <div class="go-info-btn">
                    <transition transition name="slide-btn" v-if="viewParams.gType=='building'">
                      <router-link
                        v-if="isFocus === idNum"
                        :to="'/build_info?BuildingID='+obj['BuildingID']+'&PropertyCode='+obj['PropertyCode']+'&tolink='+viewParams.tolink"
                        class="btn btn-default"
                      >
                      {{$language('詳細')}}
                        <i class="mdi mdi-arrow-right-bold"></i>
                      </router-link>
                    </transition>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="p-2">
            <code v-if="!jsonObj.total_count">{{$language('表示データがありません')}}</code>
          </div>
        </div>
        <!-- end .table-responsive -->
      </div>
      <!-- end .table-rep-plugin-->

      <div class="d-flex justify-content-end mt-1" v-if="jsonObj.total_count">
        <span>{{ (listStrage.paged - 1) * listStrage.limit + 1 }}</span>
        &nbsp;~&nbsp;
        <span>{{ (listStrage.paged - 1) * listStrage.limit + jsonObj.count }}</span>
        &nbsp;/&nbsp;
        <span>{{ jsonObj.total_count }}</span>
      </div>
      <div class="d-flex justify-content-end mt-1" v-else>
        <span>0</span>
        &nbsp;~&nbsp;
        <span>0</span>
        &nbsp;/&nbsp;
        <span>0</span>
      </div>

      <ul
        class="pagination pagination-split justify-content-end flex-wrap footable-pagination mt-1"
        v-if="jsonObj.total_count"
      >
        <li class="footable-page-arrow mb-1">
          <a href="#" @click.prevent.stop="pagenateKey('first')">«</a>
        </li>
        <li class="footable-page-arrow mb-1">
          <a href="#" @click.prevent.stop="pagenateKey('prev')">‹</a>
        </li>
        <li
          class="footable-page mb-1"
          :class="{'active': num === listStrage.paged, 'hide': pageHide(num) }"
          v-for="num in maxPage"
          :key="num"
        >
          <a href="#" @click.prevent.stop="pagenateNum(num)">{{ num }}</a>
        </li>
        <li class="footable-page-arrow mb-1">
          <a href="#" @click.prevent.stop="pagenateKey('next')">›</a>
        </li>
        <li class="footable-page-arrow mb-1">
          <a href="#" @click.prevent.stop="pagenateKey('last')">»</a>
        </li>
      </ul>
    </div>
    <!-- end .responsive-table-plugin-->
    <div v-else>
      <code>{{$language('データ取得に失敗しました')}}</code>
    </div>
  </div>
  <!-- container -->
</template>
 
<script>
import router from "../router";
import ListTableRow from "./ListTableRow.vue";
import axios from "axios";
import { inject } from "vue";
export default {
  name: "ListTable",
  props: {
    viewParams: {
      name: {
        type: String,
        default: "",
      },
      apiUrl: {
        type: String,
        default: "",
      },
      dataType: {
        type: String,
        default: "1",
      },
      gType: {
        type: String,
        default: "",
      },
      tolink: {
        type: String,
        default: "",
      },
      articleView: String,
      articleParamId: String,
    },
  },
  components: {
    ListTableRow,
  },
  data() {
    const { currentRoute } = router;
    return {
      offset: 0,
      listStrage: {
        query: {
          BuildingName:currentRoute.value.query.keyword == undefined
          ? ""
          : currentRoute.value.query.keyword
        },
        limit: 50,
        paged: 1,
      },
      maxPage: 0,
      checkKeys: [],
      jsonObj: {
        offset: 0,
        count: 0,
        total_count: 0,
        data: [Object],
      },
      tableHeaders: [],
      listData: [Object],
      isFocus: -1,
      jsonErr: false,
      isLoading: true,
      downloadAlert: "",
      stateID: "",
      cur_lan: "jp",
      lan: inject("global").language_data,
      lan_help: inject("global").language_help_data,
      moveingTypeList: [
        { value: "1", text:this.$language('一ヶ月以内') },
        { value: "2", text:this.$language('一季度以内') },
        { value: "3", text:this.$language('半年内') },
        { value: "4", text:this.$language('１年') },
        { value: "5", text:this.$language('3年') },
        { value: "6", text:this.$language('5年') },
      ],
    };
  },
  mounted() {
    let language_data = JSON.parse(localStorage.getItem("language_data"));
    this.lan = language_data;
    this.request(0);
  },
  methods: {
    search: function () {
      this.isLoading = true;
      this.listStrage.paged = 1;
      this.request(0);
    },
    pagenateNum: function (num) {
      this.isLoading = true;
      this.listStrage.paged = num;
      this.request((num - 1) * this.listStrage.limit);
    },
    pagenateKey: function (key) {
      switch (key) {
        case "next":
          if (this.listStrage.paged < this.maxPage) {
            this.isLoading = true;
            this.offset = this.listStrage.limit * this.listStrage.paged;
            this.listStrage.paged++;
          }
          break;
        case "prev":
          if (this.listStrage.paged > 1) {
            this.isLoading = true;

            this.offset = this.listStrage.limit * (this.listStrage.paged - 2);
            this.listStrage.paged--;
          }
          break;
        case "first":
          if (this.listStrage.paged > 1) {
            this.isLoading = true;
            this.offset = 0;
            this.listStrage.paged = 1;
          }
          break;
        case "last":
          if (this.listStrage.paged < this.maxPage) {
            this.isLoading = true;
            this.offset = this.listStrage.limit * (this.maxPage - 1);
            this.listStrage.paged = this.maxPage;
          }
          break;
        default:
          this.isLoading = false;
          console.log("Non-existent key");
      }
      if (this.isLoading) {
        this.request(this.offset);
      }
    },
    pageHide: function (num) {
      if (num < this.listStrage.paged - 4 || num > this.listStrage.paged + 4) {
        return true;
      }
    },
    request(startPage) {
      axios
        .get("/v1/corps_group_space", {
          params: {
            ...this.listStrage.query,
            offset: startPage,
            limit: this.listStrage.limit, //表示件数
            // BuildingName: this.BuildingName,
            // TotalAreaSqM: this.TotalAreaSqM,
            // expiryDate: this.expiryDate,
            id_token: localStorage.getItem("id_token"),
          },
        })
        .then((response) => {
          this.isLoading = false;
          this.jsonObj = response.data;
          this.tableHeaders = this.jsonObj.headers;

          this.listData = response.data.data;
          if ("vacancy_rate" in this.tableHeaders) {
            delete this.tableHeaders.vacancy_rate;
          }
          this.maxPage = Math.ceil(
            this.jsonObj.total_count / this.listStrage.limit
          );
          this.checkKeys =
            Object.keys(this.tableHeaders)[1] == 1
              ? this.tableHeaders
              : Object.keys(this.tableHeaders);
          this.industryData =
            this.jsonObj.industrys != undefined ? this.jsonObj.industrys : [];
        })
        .catch((error) => {
          console.log(error);
          this.jsonErr = true;
          this.isLoading = false;
        });
    },
  },
  watch: {
    isLoading: function () {
      if (!this.isLoading) {
        this.$nextTick(() => {
          window.parent.postMessage(["h"], "*");
        });
      } else {
        //ローディング開始のタイミングでisFocusをリセット
        this.isFocus = -1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.help {
  position: relative;
  margin-left: 10px;
  display: inline-block;
}
.help-box {
  margin-top: 5%;
  width: 60%;
}
.popup-modal {
  display: block;
}

.popup-bg {
  background: rgba(0, 0, 0, 0.25);
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  width: 100vw;
  z-index: 8;
}

.popup {
  background: #fff;
  border-radius: 0.15rem;
  box-shadow: 0 0.15rem 0.5rem rgba(0, 0, 0, 0.25);
  left: 50% !important;
  max-height: 85vh;
  max-width: 90%;
  position: fixed;
  top: 2.5rem !important;
  transform: translateX(-50%) !important;
  z-index: 16;
}

.popup iframe {
  width: 100%;
}

.fade-leave-active {
  transition: 0.25s;
  opacity: 0;
}

.fade-enter-active {
  transition: 0.25s;
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}
.tagbox {
  display: flex;
  align-items: center;
  justify-content: center;
}
.closes {
  position: absolute;
  cursor: pointer;
  right: 16px;
  top: 4px;
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  font-size: 20px;
  line-height: 26px;
  padding: 2px 9px;
  border-radius: 15px;
}
.form-inline {
  flex-wrap: nowrap;
}

.toolbar-download {
  margin-left: auto;
  margin-right: 1.5rem;
}

.toolbar-display-count {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
}

@media screen and (max-width: 576px) {
  .toolbar-search {
    flex: 1;
  }

  .toolbar-download {
    justify-content: flex-end;
    margin-bottom: 0.75rem;
    margin-right: 0;
    order: -1;
    width: 100%;
  }

  .toolbar-display {
    justify-content: flex-end;
    margin-top: 0.5em;
    width: 100%;
  }
}

.form-search {
  position: relative;
}

.form-search::before {
  content: "\f002";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  height: 1em;
  width: 1em;
}

.ddmenu-modal {
  position: relative;
  z-index: 128;
}

.ddmenu {
  background: #fff;
  border-radius: 0.15rem;
  left: 50% !important;
  padding: 2.5rem 2.5rem 1.5rem;
  position: fixed;
  top: 5.5rem !important;
  transform: translateX(-50%) !important;
  width: 80vw;
  z-index: 16;
}

.ddmenu .btn-dd-close {
  position: absolute !important;
  right: -0.5rem;
  top: -0.5rem;
}

.dropdown-menu.show {
  align-content: flex-start;
  animation: none !important;
  display: flex;
  flex-wrap: wrap;
  left: auto !important;
  margin-bottom: 1rem;
  max-height: 40rem;
  overflow-y: scroll !important;
  position: static !important;
  top: auto !important;
  width: 100%;
}

.dropdown-menu.show li {
  flex: 0 1 20%;
  word-break: break-all;
}

.ddmenu-bg {
  background: rgba(0, 0, 0, 0.25);
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  width: 100vw;
  z-index: 8;
}

.fade-leave-active {
  transition: 0.25s;
  opacity: 0;
}

.fade-enter-active {
  transition: 0.25s;
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .dropdown-menu.show li {
    flex-basis: 50%;
  }
}

@media screen and (max-width: 500px) {
  .dropdown-menu.show li {
    flex-basis: 100%;
  }
}

.table-responsive {
  overflow: auto !important;
}

table.focus-on tbody tr:hover,
table.focus-on tfoot tr:hover {
  cursor: unset;
}

.table thead tr:last-child th {
  background: #fff;
  box-shadow: 0 -0.1rem #dee2e6 inset;
  border-bottom: none;
  position: sticky;
  top: -1px;
  z-index: 8;
}

.table.table-striped td {
  white-space: normal !important;
  word-wrap: break-word;
}

.table-striped {
  // table-layout: fixed;
}

.table.table-striped .table-focus-on-td {
  align-items: center;
  min-width: auto;
  // padding: .35rem !important;
  position: sticky;
  right: 0;
}

.table-focus-on-td .btn {
  white-space: nowrap;
  transition: 0.15s;
}

// transition
.slide-btn-leave-to.btn {
  opacity: 0;
}

.slide-btn-enter-from.btn {
  transform: translateX(-1rem);
  opacity: 0;
}

.slide-btn-enter-to.btn {
  transform: translateX(0);
}

.footable-page.hide {
  display: none;
}
.toolbar-search {
  position: relative;
}
.company_box {
  position: absolute;
  z-index: 999;
  top: 36px;
  left: 44px;
  max-height: 280px;
  overflow: scroll;
  padding: 10px;
  border: 1px solid #ccc;
  background: #fff;
  width: 300px;
}
.company_box .cline {
  width: 98%;
  margin: 0 1%;
  line-height: 25px;
  cursor: pointer;
  border-top: 1px solid #ccc;
}
.company_box .cline:nth-child(1) {
  border: 0;
}
.company_box .cline:hover {
  color: #003f2d;
}
.error {
  border: 1px solid #ff0000;
}
.w-300 {
  width: 300px;
}
.w-100 {
  width: 100px !important;
}
.has-options,
.box-btn {
  float: left;
}
.has-options div {
  padding: 5px;
  margin: 5px;
  display: table;
  float: left;
  border: 1px solid #eee;
  border-radius: 5px;
}

.scoredata {
  display: flex;
  white-space: nowrap;
}

.my-btn {
  display: flex;
  width: 135px;
  margin-left: 5px;
  justify-content: space-between;
}
.my-list {
  display: flex;
  flex-direction: column;
}
.hide {
  display: none;
}
.my-select {
  span {
    border-bottom: dashed 1px #0088cc;
    cursor: pointer;
  }
}
.wide-100 {
  width: 100px;
}
.go-info-btn {
  white-space: nowrap;
}
</style>